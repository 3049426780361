<h2 mat-dialog-title>New YouMap For:</h2>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Client Name</mat-label>
    <input matInput [(ngModel)]="data.name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.name" cdkFocusInitial>Start YouMap</button>
</div>
