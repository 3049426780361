import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';


import { ClientCode } from '../../models/client-code';
import { YouMap } from '../../models/youmap';
import { YouMapCoachProperties } from '../../models/youmap-coach-properties';
import { YouMapHeader } from '../../models/youmap-header';
import { Strength } from '@app/models/strength';
import { TypedStrength } from '../../models/typed-strength';

import { YouMapService } from '../../services/youmap.service';
import { UserService } from '../../services/user.service';

import { ClientCodeDialogComponent } from '../client-code-dialog/client-code-dialog.component';
import { YouMapCreateComponent } from '../youmap-create/youmap-create.component';
import { YouMapDataDialogComponent } from '../youmap-data-dialog/youmap-data-dialog.component';
import { Consultant } from '@app/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public youMaps: YouMapHeader[];
  displayedColumns: string[] = ['name', 'status', 'code', 'view', 'generate'];
  private youMap: YouMap;
  public strengths: Strength[];
  public consultant: Consultant;

  @ViewChild(MatTable, {static: false}) youMapTable: MatTable<YouMapHeader[]>;
  @ViewChild('downloadLink',  {static: false}) private downloadLink: ElementRef;

  constructor(
    private router: Router,
    private youMapService: YouMapService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getYouMaps((data) => {
      this.youMaps = data;
    });
    this.getStrengths((res) => {
      this.strengths = res;
    });
    this.getConsultant((result) => {
      this.consultant = result;
    });
  }

  startNewYouMap(): void {
    const dialogRef = this.dialog.open(YouMapCreateComponent, {
      height: '250px',
      width: '450px',
      data: { name: '' }
    });

    dialogRef.afterClosed().subscribe(newClientName => {
      this.newYouMap(newClientName, (result) => {
        window.location.reload();
      });
    });
  }

  showClientCode(youMapId: string) {
    this.getClientCode(youMapId, (clientCode) => {
      this.dialog.open(ClientCodeDialogComponent, {
        height: '200px',
        width: '550px',
        data: clientCode
      });
    });
  }

  getClientCode(youMapId: string, callback: (data: ClientCode) => void) {
    this.youMapService.getClientCode(youMapId)
      .subscribe(res => {
        callback(res);
      });
  }

  getConsultant(callback: (data: Consultant) => void) {
    this.userService.getConsultant()
      .subscribe(res => { callback(res); });
  }

  viewYouMapData(youMapId: string): void {
    console.log('Opening the View/Edit dialog for YouMapId: ' + youMapId);

    this.getYouMapData(youMapId, (result) => {
      const youMapData: YouMapCoachProperties = result;
      if (!youMapData.strengths) {
        youMapData.strengths = [];
      }
      if (youMapData.strengths.length < 5) {
        for (let i = youMapData.strengths.length; i < 5; i++) {
          const emptyTypedStrength: TypedStrength = {
            id: null, name: '', strength: { id: null, name: '', theme: '', description: '' }, strengthType: '', orderId: i + 1
          };
          youMapData.strengths.push(emptyTypedStrength);
        }
      }

      const dialogData = { youMapData: youMapData, strengths: this.strengths };
      const dialogRef = this.dialog.open(YouMapDataDialogComponent, {
        height: '700px',
        width: '600px',
        autoFocus: false,
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.updateCoachData(res);
        }
      });
    });
  }

  generateYouMap(youMapId: string) {

    this.youMapService.downloadYouMap(youMapId).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = 'YouMap.pdf';
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      }
    );

  }


  getYouMaps(callback: (data: YouMapHeader[]) => void) {
    this.youMapService.getYouMapHeaders()
      .subscribe(res => { callback(res); });
  }

  newYouMap(clientName: string, callback: (data: YouMap) => void) {
    const youMap: YouMap = {
      id: '00000000-0000-0000-0000-000000000000', name: clientName, careerType: null, strengths: null, skills: null,
      values: null, uniqueValueContribution: null, status: null, consultant: null
    };
    this.youMapService.saveYouMap(youMap)
      .subscribe(result => { callback(result); });

  }

  getYouMapData(youMapId: string, callback: (data: YouMapCoachProperties) => void) {
    this.youMapService.getYouMapCoachProperties(youMapId)
      .subscribe(res => { callback(res); });
  }

  getStrengths(callback: (data: Strength[]) => void) {
    this.youMapService.getStrengths()
      .subscribe(res => { callback(res); });
  }

  updateCoachData(youMapData: YouMapCoachProperties) {
    if (youMapData !== null && youMapData.strengths !== null) {
      for (let i = 0; i < youMapData.strengths.length; i++) {
        youMapData.strengths[i].strength = null;
      }
    }
    this.youMapService.updateYouMapCoachProperties(youMapData).subscribe(res => {
      window.location.reload();
    });
  }

}
