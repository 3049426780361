import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { NewYouMapData } from '../../models/new-youmap-data';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-home',
  templateUrl: './youmap-create.component.html',
  styleUrls: ['./youmap-create.component.scss']
})
export class YouMapCreateComponent {

  constructor(
    public dialogRef: MatDialogRef<YouMapCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewYouMapData) {}

  cancel(): void {
    this.dialogRef.close();
  }
}
