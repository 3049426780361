import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

// import custom validator to validate that password and confirm password fields match
import { MustMatch } from '../../form-utils/must-match.validator';
import { ChangePassword } from '../../models/change-password';
import { UserService } from '../../services/user.service';

@Component({  templateUrl: './change-password.component.html' })
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
    submitted = false;
    error = '';
    loading = false;

    constructor(private formBuilder: FormBuilder, private router: Router, private userService: UserService) { }

    ngOnInit() {
        this.changePasswordForm = this.formBuilder.group({
            currentPassword: ['', Validators.required],
            newPassword: ['', [Validators.required, Validators.minLength(6)]],
            newPasswordConfirm: ['', Validators.required]
        }, {
            validator: MustMatch('newPassword', 'newPasswordConfirm')
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.changePasswordForm.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.changePasswordForm.invalid) {
            return;
        }

        this.loading = true;
        const changePassword: ChangePassword = {currentPassword: this.f.currentPassword.value, newPassword: this.f.newPassword.value };
        this.userService.changePassword(changePassword)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate(['/']);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

    onReset() {
        this.submitted = false;
        this.changePasswordForm.reset();
    }
}
