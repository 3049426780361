<h2>YouMap Data for: {{youMapData.name}}</h2>
<hr />
<h4>Name</h4>
<mat-form-field id="name">
  <input matInput [(ngModel)]="youMapData.name" />
</mat-form-field>
<hr />
<h4>Strengths (In Order)</h4>
<ul id="strengths-list">
  <li>
    <mat-form-field>
      <mat-label>Strength 1</mat-label>
      <mat-select [(ngModel)]="youMapData.strengths[0].id">
        <mat-option *ngFor="let strength of strengths" [value]="strength.id">
          {{strength.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </li>
  <li>
    <mat-form-field>
      <mat-label>Strength 2</mat-label>
      <mat-select [(ngModel)]="youMapData.strengths[1].id">
        <mat-option *ngFor="let strength of strengths" [value]="strength.id">
          {{strength.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </li>
  <li>
    <mat-form-field>
      <mat-label>Strength 3</mat-label>
      <mat-select [(ngModel)]="youMapData.strengths[2].id">
        <mat-option *ngFor="let strength of strengths" [value]="strength.id">
          {{strength.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </li>
  <li>
    <mat-form-field>
      <mat-label>Strength 4</mat-label>
      <mat-select [(ngModel)]="youMapData.strengths[3].id">
        <mat-option *ngFor="let strength of strengths" [value]="strength.id">
          {{strength.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </li>
  <li>
    <mat-form-field>
      <mat-label>Strength 5</mat-label>
      <mat-select [(ngModel)]="youMapData.strengths[4].id">
        <mat-option *ngFor="let strength of strengths" [value]="strength.id">
          {{strength.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </li>
</ul>
<div style="display:none;">
<hr />
<h4>Unique Value Statement</h4>
<mat-form-field id="unique-value">
  <textarea matInput [(ngModel)]="youMapData.uniqueValueContribution" cdkTextareaAutosize
    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"></textarea>
</mat-form-field>
</div>
<hr />
<h4>Client Asssement Progress</h4>
<table class="client-assessment-progress">
  <tr><th>Assessment</th><th>Completed</th></tr>
  <tr><td>Skills</td><td><i *ngIf="youMapData.skillsCompleted" class="material-icons green">check_circle_outline</i></td></tr>
  <tr><td>Values</td><td><i *ngIf="youMapData.valuesCompleted" class="material-icons green">check_circle_outline</i></td></tr>
  <tr><td>Career Interests</td><td><i *ngIf="youMapData.careerInterestsCompleted" class="material-icons green">check_circle_outline</i></td></tr>
  <tr><td colspan="2"><span *ngIf="youMapData.careerInterestsCompleted">{{youMapData.careerInterestScores}}</span></td></tr>
</table>
<hr />
<mat-checkbox labelPosition="before" [(ngModel)]="youMapData.isComplete" name="isComplete"
  [disabled]="disableCompleteCheckbox">YouMap is Complete: </mat-checkbox>
<div class="clear-fix">&nbsp;</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="youMapData" cdkFocusInitial
    [disabled]="youMapData.isComplete && youMapData.canBeCompleted === false">Save YouMap</button>
</div>

