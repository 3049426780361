import { Injectable, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { Observable, of  } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpRequest } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { ClientCode } from '../models/client-code';
import { Strength } from '../models/strength';
import { User } from '../models/user';
import { YouMap } from '../models/youmap';
import { YouMapHeader } from '../models/youmap-header';
import { YouMapCoachProperties } from '@app/models/youmap-coach-properties';

@Injectable({ providedIn: 'root' })
export class YouMapService {
    private currentUser: User;
    private headers: HttpHeaders;
    private qsParams: HttpParams;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    }

    downloadYouMap(id: string): Observable<HttpEvent<Blob>> {
      this.qsParams = new HttpParams().set('youMapId', id);
      return this.http.request(new HttpRequest('GET', `${environment.apiUrl}youmap/generate`, null,
      {headers: this.headers, params: this.qsParams, reportProgress: true, responseType: 'blob'}));
    }

    getYouMap(id: string): Observable<YouMap> {
      this.qsParams = new HttpParams().set('youMapId', id);
      return this.http.get<YouMap>
      (`${environment.apiUrl}youmap`, {headers: this.headers, params: this.qsParams})
      .pipe(catchError(this.handleError<YouMap>('getYouMap', null)));
    }


    getYouMaps(): Observable<YouMap[]> {
      this.qsParams = new HttpParams().set('consultantId', this.currentUser.consultantId);
      return this.http.get<YouMap[]>
      (`${environment.apiUrl}youmap/list`, {headers: this.headers, params: this.qsParams})
      .pipe(catchError(this.handleError<YouMap[]>('getYouMaps', [])));
    }

    getYouMapHeaders(): Observable<YouMapHeader[]> {
      this.qsParams = new HttpParams().set('consultantId', this.currentUser.consultantId);
      return this.http.get<YouMapHeader[]>
      (`${environment.apiUrl}youmap/headers`, {headers: this.headers, params: this.qsParams})
      .pipe(catchError(this.handleError<YouMapHeader[]>('getYouMapHeaders', [])));
    }

    getYouMapCoachProperties(id: string): Observable<YouMapCoachProperties> {
      this.qsParams = new HttpParams().set('youMapId', id);
      return this.http.get<YouMapCoachProperties>
      (`${environment.apiUrl}youmap/coach-set-properties`, {headers: this.headers, params: this.qsParams})
      .pipe(catchError(this.handleError<YouMapCoachProperties>('getYouMapCoachProperties', null)));
    }

    updateYouMapCoachProperties(youMapData: YouMapCoachProperties): Observable<YouMap> {
       return this.http.post<YouMap>(`${environment.apiUrl}youmap/coach-set-properties`, youMapData, {headers: this.headers})
      .pipe(catchError(this.handleError<YouMap>('updateYouMapCoachProperties', null)));
    }

    saveYouMap(youMap: YouMap) {
      const data = JSON.stringify(youMap);
      return this.http.post<YouMap>(`${environment.apiUrl}youmap`, youMap, {headers: this.headers})
      .pipe(catchError(this.handleError<YouMap>('saveYouMap', null)));

    }

    createNewYouMap(name: string){
      return this.http.post<YouMap>(`${environment.apiUrl}youmap/new`, { name }, {headers: this.headers})
      .pipe(catchError(this.handleError<YouMap>('createNewYouMap', null)));
    }

    getClientCode(youMapId: string)
    {
      this.qsParams = new HttpParams().set('youMapId', youMapId);
      return this.http.get<ClientCode>
      (`${environment.apiUrl}youmap/clientCode`, {headers: this.headers, params: this.qsParams})
      .pipe(catchError(this.handleError<ClientCode>('getClientCode', null)));
    }

    getStrengths(): Observable<Strength[]> {
      return this.http.get<Strength[]>(`${environment.apiUrl}strengths`,  { headers: this.headers })
      .pipe(catchError(this.handleError<Strength[]>('getStrengths', [])));
    }

    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        console.error(error);
        return of(result as T);
      };
    }

}
