import { Component, Inject, ViewChild, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

import { YouMapCoachProperties } from '../../models/youmap-coach-properties';
import { Strength } from '@app/models/strength';
import { StrengthType } from '@app/models/strength-type';

@Component({
  selector: 'app-home',
  templateUrl: './youmap-data-dialog.component.html',
  styleUrls: ['./youmap-data-dialog.component.scss']
})
export class YouMapDataDialogComponent {
  public youMapData: YouMapCoachProperties;
  public name: string;
  public strengths: Strength[];
  public disableCompleteCheckbox: boolean;

  constructor(
    public dialogRef: MatDialogRef<YouMapDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _ngZone: NgZone) {
   
    this.name = data.name;
    this.strengths = data.strengths;
    this.youMapData = data.youMapData;
    if (this.youMapData.canBeCompleted || this.youMapData.isComplete) {
      this.disableCompleteCheckbox = false;
    } else {
      this.disableCompleteCheckbox = true;
    }

  }

  @ViewChild('autosize',  {static: false}) autosize: CdkTextareaAutosize;


  cancel(): void {
    this.dialogRef.close();
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }




}
