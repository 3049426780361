import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { ClientCode } from '../../models/client-code';

@Component({
  selector: 'app-home',
  templateUrl: './client-code-dialog.component.html'
})
export class ClientCodeDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ClientCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientCode) {}


  cancel(): void {
    this.dialogRef.close();
  }
}
