import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../models';
import { ChangePassword } from '../models/change-password';
import {Consultant } from '../models/consultant';

@Injectable({ providedIn: 'root' })
export class UserService {
    private headers: HttpHeaders;
    private currentUser: User;

    constructor(private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    }

    changePassword(changePassword: ChangePassword) {
        return this.http.post<any>(`${environment.apiUrl}auth/consultant/change-password`, changePassword, {headers: this.headers})
          .pipe(catchError(this.handleError<any>('changePassword', null)));
    }

    getConsultant(): Observable<Consultant> {
      return this.http.get<Consultant>(`${environment.apiUrl}consultants/consultant`, {headers: this.headers})
      .pipe(catchError(this.handleError<Consultant>('consultant', null)));

    }
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        console.error(error);
        return of(result as T);
      };
    }
}
