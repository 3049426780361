<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark border-bottom box-shadow mb-3">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">YouMap Coach Portal</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <ng-container *ngIf="showLogout()">
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-white" [routerLink]="['/logout']">Logout</a>
          </li>
        </ng-container>
        </ul>
      </div>
    </div>
  </nav>
</header>
