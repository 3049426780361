<div class="col-md-6 offset-md-3 mt-5">
  <div class="card">
      <h4 class="card-header">Change Your Password</h4>
      <div class="card-body">
          <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="currentPassword">Current Password</label>
              <input type="password" formControlName="currentPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.currentPassword.errors }" />
              <div *ngIf="submitted && f.currentPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.currentPassword.errors.required">Current Password is required</div>
              </div>
          </div>  <div class="form-group">
            <label for="passwnewPasswordord">New Password</label>
            <input type="password" formControlName="newPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" />
            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                <div *ngIf="f.newPassword.errors.required">New Password is required</div>
            </div>
        </div>
              <div class="form-group">
                  <label for="newPasswordConfirm">Confirm New Password</label>
                  <input type="password" formControlName="newPasswordConfirm" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.newPasswordConfirm.errors }" />
                  <div *ngIf="submitted && f.newPasswordConfirm.errors" class="invalid-feedback">
                      <div *ngIf="f.newPasswordConfirm.errors.required">Confirm New Password is required</div>
                  </div>
              </div>
              <button [disabled]="loading" class="btn btn-primary">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Change Password
              </button>
              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
          </form>
      </div>
  </div>
</div>
