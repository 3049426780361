<h3>Your YouMap® Coach Dashboard</h3>

<div id="consultant-actions">
  <button class="mat-raised-button trailing-space-20" (click)="startNewYouMap()"  [disabled]="consultant?.availableCredits === 0">Start a new YouMap</button><button class="mat-raised-button" onclick="location.href='mailto:kristin@myyoumap.com?subject=Ordering%20YouMap%20credits'">Order Credits</button>
  <h4 id="available-credits">Credits Remaining: {{consultant?.availableCredits}}</h4>
</div>
<div id="you-maps">
  <h4>Your YouMaps</h4>
  <table mat-table [dataSource]="youMaps" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let youMapHeader"> {{youMapHeader.name}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let youMapHeader"> {{youMapHeader.status.name}} </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef> Client Code </th>
      <td mat-cell *matCellDef="let youMapHeader"> <button class="mat-button" (click)="showClientCode(youMapHeader.id)">Get Client Code</button> </td>
    </ng-container>

    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef> View/Edit YouMap Data </th>
      <td mat-cell *matCellDef="let youMapHeader"> <button class="mat-button" (click)="viewYouMapData(youMapHeader.id)">View/Edit YouMap Data</button> </td>
    </ng-container>

    <ng-container matColumnDef="generate">
      <th mat-header-cell *matHeaderCellDef> Generate YouMap </th>
      <td mat-cell *matCellDef="let youMapHeader"> <button class="mat-button" [disabled]="youMapHeader.status.name !== 'Completed'" (click)="generateYouMap(youMapHeader.id)">Generate YouMap</button> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>
<a class="download-link" #downloadLink></a>
